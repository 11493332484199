import React, { ReactElement } from "react";
import creator from "./images/creator.svg";
import { MdOutlineVerified } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import supporter1 from "./images/supporter-1.svg";
import supporter2 from "./images/supporter-2.svg";
import supporter3 from "./images/supporter-3.svg";
import thumbnail from "./images/thumbnail.svg";
import star from "./images/star.svg";
import starEmpty from "./images/star-empty.svg";
import classes from "./WelcomePage.module.scss";
import { TFunction } from "i18next";

export type UsageStepCard = {
    image: string;
    title:
        | "welcomePage.steps.step1.title"
        | "welcomePage.steps.step2.title"
        | "welcomePage.steps.step3.title"
        | "welcomePage.steps.step4.title"
        | "welcomePage.steps.step5.title"
        | "welcomePage.steps.step6.title";
    text:
        | "welcomePage.steps.step1.text"
        | "welcomePage.steps.step2.text"
        | "welcomePage.steps.step3.text"
        | "welcomePage.steps.step4.text"
        | "welcomePage.steps.step5.text"
        | "welcomePage.steps.step6.text";
    bgColor?: string;
    code?: ReactElement;
};

export const UsageSteps: UsageStepCard[] = [
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-1.png",
        title: "welcomePage.steps.step1.title",
        text: "welcomePage.steps.step1.text",
        bgColor: "bg-white",
        code: (
            <div
                id="2b0c3de9-c7bc-4060-ba38-d4f9646b02c5"
                className="row w-100 border rounded py-3"
                // style={{
                //     boxShadow:
                //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                // }}
            >
                <div id="ffaab72b-2a6a-468e-90b3-a5c855ef4d4f" className="col">
                    <h6 id="19227f85-bf6d-4487-be2b-947cb9c3b761">
                        Start a petition
                    </h6>
                    <hr id="74664b9e-7180-42b3-8ccf-7c6003dfffba" />
                    <form id="54a19cbf-898a-4e80-980a-dd0e1100ab09">
                        <div
                            id="e964ecc8-4ae6-4a07-a403-3300412624c7"
                            className="mb-3"
                        >
                            <div
                                id="bab09a25-7eb8-4fd7-9cf6-81abf777031f"
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div
                                    id="be7814c9-bbb6-4fbd-b8bb-8247772a60d4"
                                    className="d-flex align-items-center"
                                >
                                    <img
                                        id="db06cf12-90f8-4dd2-9790-551dd1d1b4e5"
                                        className="me-2"
                                        src={creator}
                                        style={{
                                            width: "45px",
                                            height: "45px",
                                        }}
                                        alt=""
                                    />
                                    <div
                                        id="94e770f4-0b0f-4fe6-9a20-c7a594f09475"
                                        className="d-flex flex-column"
                                    >
                                        <div
                                            id="f91c423b-11e9-4efa-9eaf-7857a19624c1"
                                            className="d-flex align-items-center gap-1"
                                        >
                                            <div
                                                id="6283ba0a-e5ca-4226-9f37-df4c4a3acdab"
                                                className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                            >
                                                <span id="1a189582-6183-4ab1-9e46-36d9d9ce0d80">
                                                    Prof. John Smith
                                                </span>
                                            </div>
                                            <MdOutlineVerified
                                                id="66921473-e953-443b-b0ca-3fc7d571375e"
                                                className="text-primary"
                                                style={{ marginTop: "0.2rem" }}
                                            />
                                        </div>
                                        <span
                                            id="19232cb5-0f7b-4f0a-8698-0866f63288d1"
                                            className="form-text mt-0"
                                        >
                                            @johnsmith
                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="db0ebe9d-78ce-42b1-988f-50ab16d138c6"
                                    className="btn btn-outline-danger"
                                >
                                    <AiOutlineDelete
                                        id="db0ebe9d-78ce-42b1-988f-50ab16d138c6-icon"
                                        className="my-1"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            id="5e87e0f1-8a81-4a33-9496-75233b9d521f"
                            className="mb-3"
                        >
                            <div
                                id="59fa974e-b440-49e6-96cc-227305ba251a"
                                className="w-100 border rounded p-2"
                            >
                                <p
                                    id="91ffdd53-d691-4c07-a98b-28e563172dd4"
                                    className="m-0"
                                >
                                    Kinetic vs Potential energy
                                </p>
                            </div>
                        </div>
                        <div
                            id="8d35dd5f-b90d-40ec-b01c-9c710ac303e3"
                            className="mb-3"
                        >
                            <div
                                id="a97a73e0-072d-4ca6-88e4-ebdfc2872dc2"
                                className="w-100 border rounded p-2"
                            >
                                <p id="ffc7d4cc-8770-4ff2-becd-18c48039aa01">
                                    Professor Smith, I appreciate how you
                                    simplify complex physics concepts.
                                </p>
                                <p id="de086c54-c735-46ad-8777-7befe1330542">
                                    Could you please make a video explaining the
                                    difference between kinetic and potential
                                    energy and how one transforms into another?
                                </p>
                                <p id="d971bd8a-a4c8-4af8-ab70-4a0eb3c7a8ec">
                                    I've watched numerous videos on this matter,
                                    but I'm particularly keen to get your
                                    perspective on the subject.
                                </p>
                            </div>
                        </div>
                        <div
                            id="a6e39c41-5c78-4d32-8257-6f77d6ef8207"
                            className="d-flex flex-wrap gap-3 justify-content-between mb-3"
                        >
                            <div
                                id="312a30e9-a60f-4b17-b2bc-9f49c0636356"
                                className="d-flex gap-2 align-items-center"
                            >
                                <span id="42ab7bfe-0912-469b-9df1-d35bce22b424">
                                    Need the content in:
                                </span>
                                <span
                                    id="f3eeb527-82cb-42cc-aac5-9147c202fad9"
                                    className="border rounded p-2 px-3 text-center"
                                >
                                    30
                                </span>
                                <span id="be18baea-10f3-4247-9feb-c651176c827a">
                                    days
                                </span>
                            </div>
                            <div
                                id="c787ed5a-6c3b-4ffb-b393-fad65f252232"
                                className="d-flex gap-2 align-items-center"
                            >
                                <span id="41654d8a-e907-4bcb-82d1-f5818f6dede3">
                                    Pledge:
                                </span>
                                <span
                                    id="6012043a-4a07-4cd3-9c52-cd36969ed18d"
                                    className="border rounded p-2 px-3 text-center"
                                >
                                    $20.00
                                </span>
                            </div>
                        </div>
                        <div
                            id="0e7083a7-3f45-42fc-9fa9-df3b4bec9b9c"
                            className="d-flex justify-content-end"
                        >
                            <div
                                id="a5145c5e-6b6d-4d7a-bd9a-9b346310e0e6"
                                className="btn btn-primary"
                            >
                                Start
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ),
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-2.png",
        title: "welcomePage.steps.step2.title",
        text: "welcomePage.steps.step2.text",
        bgColor: "bg-white",
        code: (
            <div
                id="90ad7db3-5051-484f-b296-7e5cd188a47a"
                className="d-flex flex-column gap-3"
            >
                <div
                    id="a4540b71-17e2-44d9-bff6-fb2f8a2cdc28"
                    className="row justify-content-end px-3"
                >
                    <div
                        id="8a6fc80f-4630-4123-90c2-08c5ec4eae84"
                        className="col col-sm-9 col-md-12 col-lg-9 border rounded p-3"
                        // style={{
                        //     boxShadow:
                        //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        // }}
                    >
                        <h6 id="f1a4af01-c792-4d8e-942f-4fa878ef43d6">
                            Support the petition
                        </h6>
                        <hr id="0ed500e9-4ad1-44b1-bbf0-b5c40415cea1" />
                        <div
                            id="db3ea629-4648-4619-a4b9-0313a362316f"
                            className="d-flex justify-content-between gap-3"
                        >
                            <label
                                id="43adf419-50f3-428f-99ac-85327725e493"
                                htmlFor="pledgeAmountInput"
                                className="col-form-label"
                            >
                                Pledge:
                            </label>
                            <div
                                id="b71c0eea-ae40-43c3-9aaf-6d439fadde85"
                                className="col"
                            >
                                <div
                                    id="251dc4f6-490d-4d78-8ea9-5ebe12b4a434"
                                    className="border rounded p-2 w-100 text-center"
                                >
                                    $25.00
                                </div>
                            </div>
                            <button
                                id="4fc13b0e-5dc0-4566-ac01-4b6c0b60f68c"
                                className="btn btn-dark"
                            >
                                Support
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    id="d519964a-b307-45b4-9065-31ac80f01f28"
                    className="border rounded w-100"
                    // style={{
                    //     boxShadow:
                    //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    // }}
                >
                    <div
                        id="fa4998b9-cb9a-4d32-ace3-e3486e5fa65f"
                        className="d-flex flex-column gap-3 p-3"
                    >
                        <div
                            id="8f10d9fe-b36a-411b-bd20-b283093e7012"
                            className="d-flex flex-column border-bottom gap-3"
                        >
                            <h5
                                id="d41778cc-4306-495c-841e-1dfee5ba24ba"
                                className="card-title"
                            >
                                Kinetic vs Potential energy
                            </h5>
                            <div id="690cf748-da3a-45eb-a6d4-24bc65815ea3">
                                <p id="027575cb-c950-4860-989b-4988d431154d">
                                    Professor Smith, I appreciate how you
                                    simplify complex physics concepts.
                                </p>
                                <p id="7748fb0a-769e-48d4-a05f-d6d50055498d">
                                    Could you please make a video explaining the
                                    difference between kinetic and potential
                                    energy and how one transforms into another?
                                </p>
                                <p id="25a9cb1f-9211-464b-ad50-cf4881cc630c">
                                    I've watched numerous videos on this matter,
                                    but I'm particularly keen to get your
                                    perspective on the subject.
                                </p>
                            </div>
                        </div>
                        <div
                            id="88f735a8-303f-42f8-8df0-435c7db5c6fe"
                            className="d-flex align-items-center justify-content-between"
                        >
                            <div
                                id="03d09363-84bb-496a-9876-8baac2bc8b84"
                                className="d-flex align-items-center"
                            >
                                <img
                                    id="da328935-9e91-4083-a9a6-963270c9e73b"
                                    className="me-2"
                                    src={creator}
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                    }}
                                    alt=""
                                />
                                <div
                                    id="23460896-6639-4f9e-baeb-58ffc85ba6bd"
                                    className="d-flex flex-column"
                                >
                                    <div
                                        id="af0cfc67-a551-4fed-a7cd-ef3ed2e64b96"
                                        className="d-flex align-items-center gap-1"
                                    >
                                        <div
                                            id="27652ef8-1837-458c-9f9b-38f8bc228a35"
                                            className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                        >
                                            <span id="28b36245-c343-46cf-b283-7d1a4fade864">
                                                Prof. John Smith
                                            </span>
                                        </div>
                                        <MdOutlineVerified
                                            id="c03608bb-145e-4256-91e8-d5f78e556239"
                                            className="text-primary"
                                            style={{ marginTop: "0.2rem" }}
                                        />
                                    </div>
                                    <span
                                        id="c3a1df9f-19d3-483d-b870-fe065fe69aeb"
                                        className="form-text mt-0"
                                    >
                                        @johnsmith
                                    </span>
                                </div>
                            </div>
                            <div
                                id="401b3016-12c9-425d-9dae-c5a613c9be02"
                                className="d-flex flex-column gap-3 flex-sm-row align-items-center"
                            >
                                <span
                                    id="f678c58b-1c61-4f16-b695-20dc7bb2a501"
                                    className="text-dark m-0"
                                >
                                    138 Supporters
                                </span>
                                <span
                                    id="b1318ef3-61d8-4426-bca5-dc2423f55f7f"
                                    className="text-dark m-0"
                                >
                                    $2,346.00
                                </span>
                            </div>
                        </div>
                        <div
                            id="a9f45ec6-ce6d-4364-abb5-97279b018838"
                            className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                        >
                            <div
                                id="b29bd295-687a-4084-94a4-b590de9c75f5"
                                className="d-flex flex-fill justify-content-end gap-2"
                            >
                                <button
                                    id="f71dfdb8-5c99-4fed-9b2b-393c9359e1ed"
                                    className="btn btn-primary"
                                    title="Support"
                                >
                                    Support
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        id="35f1dfe2-52ec-4b84-8924-8237daf9209c"
                        className="rounded-bottom bg-warning-subtle mt-0 py-1"
                    >
                        <div
                            id="76207463-e8f8-48a9-8897-94c55c4c1151"
                            className="d-flex justify-content-center gap-2 text-warning-emphasis"
                        >
                            ONGOING
                        </div>
                        <div
                            id="700161c0-7269-4003-83b9-c40b46f0b989"
                            className="d-flex justify-content-center mt-0 form-text text-warning-emphasis"
                        >
                            <span id="38ec236e-4098-476f-aa15-3a0dc9263045">
                                Time remaining to fulfill: 14d 10h 37m 19s
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ),
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-3.png",
        title: "welcomePage.steps.step3.title",
        text: "welcomePage.steps.step3.text",
        bgColor: "bg-white",
        code: (
            <div
                id="6a3f21b5-f2d0-4d4e-a30c-ed0cc30d8b2e"
                className="d-flex flex-column gap-3"
            >
                <div
                    id="ffb8ca1e-9f5b-46bc-bded-f58b4dc335a4"
                    className="row justify-content-end px-3"
                >
                    <div
                        id="f6d2cce9-4f9f-4285-ad56-9f8d81530da7"
                        className="col col-sm-9 col-md-12 col-lg-9 border rounded p-3"
                        // style={{
                        //     boxShadow:
                        //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        // }}
                    >
                        <h6 id="887d403e-4e59-4f28-b2d9-63a26ce1ad15">
                            Supporters
                        </h6>
                        <hr id="2830dc46-35a1-4892-bd55-e15dfb8acfb8" />
                        <div
                            id="11e650b0-7053-419c-a070-30e57dfbd6b7"
                            className="d-flex flex-column gap-2"
                        >
                            <div
                                id="6c92d493-511a-4e9d-bbfd-316dd77217ad"
                                className="d-flex align-items-center justify-content-between px-2 py-3 border rounded"
                            >
                                <div
                                    id="f28729a0-b25c-4b25-aa25-11952180baf9"
                                    className="d-flex align-items-center"
                                >
                                    <img
                                        id="0dde1d66-1963-41f5-9699-fd291d1fa2a0"
                                        src={supporter1}
                                        alt="profile"
                                        width="45"
                                        height="45"
                                        className="rounded-circle me-2"
                                    />
                                    <div
                                        id="0d498ee7-8e9a-4bd6-9b23-9a5715348902"
                                        className="d-flex flex-column"
                                    >
                                        <div
                                            id="b6576c23-aad3-4886-858a-7655449f5687"
                                            className="d-flex align-items-center gap-1"
                                        >
                                            <div
                                                id="fd5463a9-7c50-4ba9-bf37-d4034f2c7190"
                                                className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                            >
                                                <span id="0f73c39c-1576-4f21-82d5-181b2555a63e">
                                                    Emma West
                                                </span>
                                            </div>
                                        </div>
                                        <span
                                            id="ae613af7-0b88-4dbc-b2bf-288b71a2d43b"
                                            className="form-text mt-0"
                                        >
                                            @emmawest
                                        </span>
                                    </div>
                                </div>
                                <span
                                    id="d4626b7a-cac6-4fcb-ad65-20622cdf368d"
                                    className="text-dark"
                                >
                                    USD 25.00
                                </span>
                            </div>
                            <div
                                id="ff49a4be-fef0-4d12-b1d8-34684bd4235a"
                                className="d-flex align-items-center justify-content-between px-2 py-3 border rounded"
                            >
                                <div
                                    id="fd80f49a-0a9d-42fb-84b7-f61a4119bd61"
                                    className="d-flex align-items-center"
                                >
                                    <img
                                        id="510ae6e9-7fe2-438d-b516-07d0cc53360e"
                                        src={supporter2}
                                        alt="profile"
                                        width="45"
                                        height="45"
                                        className="rounded-circle me-2"
                                    />
                                    <div
                                        id="1eb1bb64-e8d7-408a-ad7a-08efdc7a1d3c"
                                        className="d-flex flex-column"
                                    >
                                        <div
                                            id="f84c2ae8-8475-4a5c-bfb8-b8bc958a66fa"
                                            className="d-flex align-items-center gap-1"
                                        >
                                            <div
                                                id="63be88bb-979b-488c-9410-cc6636d1e58a"
                                                className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                            >
                                                <span id="13d6fb09-0f67-43cb-bd42-553c70d03010">
                                                    Oliver Brown
                                                </span>
                                            </div>
                                        </div>
                                        <span
                                            id="8fcf4325-491e-488c-bec2-44b9270fecac"
                                            className="form-text mt-0"
                                        >
                                            @oliverbrown
                                        </span>
                                    </div>
                                </div>
                                <span
                                    id="27f6e78f-c0f3-4927-a468-076f4e2bdb91"
                                    className="text-dark"
                                >
                                    USD 15.00
                                </span>
                            </div>
                            <div
                                id="f4334000-4e28-4dec-bec9-e38a2f48b1bf"
                                className="d-flex align-items-center justify-content-between px-2 py-3 border rounded"
                            >
                                <div
                                    id="7fde659f-5bce-4913-896e-0ce5481a0de6"
                                    className="d-flex align-items-center"
                                >
                                    <img
                                        id="dcf7fa2c-4437-460f-a232-6253d7750d67"
                                        src={supporter3}
                                        alt="profile"
                                        width="45"
                                        height="45"
                                        className="rounded-circle me-2"
                                    />
                                    <div
                                        id="cca8d053-9045-4235-9bb7-326762330f03"
                                        className="d-flex flex-column"
                                    >
                                        <div
                                            id="f478704b-10dd-4b3d-8264-321371d121ff"
                                            className="d-flex align-items-center gap-1"
                                        >
                                            <div
                                                id="a8c1b32f-c443-4a9a-94c5-474469b33304"
                                                className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                            >
                                                <span id="836ddd89-c86f-4f22-b472-d27b27d46003">
                                                    Amy Olsen
                                                </span>
                                            </div>
                                        </div>
                                        <span
                                            id="8d988e52-2696-4c43-9d26-43c1bb1b07e4"
                                            className="form-text mt-0"
                                        >
                                            @amyolsen
                                        </span>
                                    </div>
                                </div>
                                <span
                                    id="e3812d7d-0687-4552-8e53-f46902945462"
                                    className="text-dark"
                                >
                                    USD 10.00
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="7611abb5-a855-4b33-ad6d-ca037931d406"
                    className="border rounded w-100"
                    // style={{
                    //     boxShadow:
                    //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    // }}
                >
                    <div
                        id="0cbf9428-a88f-463d-900c-96fee8d96436"
                        className="d-flex flex-column gap-3 p-3"
                    >
                        <div
                            id="3555a390-fc72-4ca6-b6df-7505c34cb95f"
                            className="d-flex flex-column border-bottom gap-3"
                        >
                            <h5
                                id="ce2e4809-8674-4bae-9626-27e6cea6cf49"
                                className="card-title"
                            >
                                Kinetic vs Potential energy
                            </h5>
                            <div id="4e5be65d-c752-4581-b2e7-0cc20a370e2b">
                                <p id="461ba0c0-da55-4cc5-922e-a0dd598e9ac5">
                                    Professor Smith, I appreciate how you
                                    simplify complex physics concepts.
                                </p>
                                <p id="1e7b2872-f0b2-4c2f-8be0-95b8d8ea1919">
                                    Could you please make a video explaining the
                                    difference between kinetic and potential
                                    energy and how one transforms into another?
                                </p>
                                <p id="cee9a401-55aa-46d6-a7db-3e4caa90f83c">
                                    I've watched numerous videos on this matter,
                                    but I'm particularly keen to get your
                                    perspective on the subject.
                                </p>
                            </div>
                        </div>
                        <div
                            id="5b7a31d7-9bd4-4da2-81b8-3498d2f19d61"
                            className="d-flex align-items-center justify-content-between"
                        >
                            <div
                                id="41f0b75e-858f-40d5-b024-53ee4c62522c"
                                className="d-flex align-items-center"
                            >
                                <img
                                    id="ab1231e6-4915-4198-9dae-9a0466089c62"
                                    className="me-2"
                                    src={creator}
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                    }}
                                    alt=""
                                />
                                <div
                                    id="5cd37e25-bf35-4946-81c3-20bcca31154e"
                                    className="d-flex flex-column"
                                >
                                    <div
                                        id="8d937cab-350e-4323-9e33-a8c5ca6b5ea1"
                                        className="d-flex align-items-center gap-1"
                                    >
                                        <div
                                            id="ac588a5c-5884-4975-b8f2-3efbac2d782d"
                                            className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                        >
                                            <span id="dad75bd4-951e-41f8-b6bc-55032082d028">
                                                Prof. John Smith
                                            </span>
                                        </div>
                                        <MdOutlineVerified
                                            id="a87804e8-0886-4949-a80f-417ef0c7b2a2"
                                            className="text-primary"
                                            style={{ marginTop: "0.2rem" }}
                                        />
                                    </div>
                                    <span
                                        id="25e447ed-9e9d-455c-a30a-cb339d924c5f"
                                        className="form-text mt-0"
                                    >
                                        @johnsmith
                                    </span>
                                </div>
                            </div>
                            <div
                                id="9070681f-eed0-4c5b-967b-443816823bf3"
                                className="d-flex flex-column gap-3 flex-sm-row align-items-center"
                            >
                                <span
                                    id="090e3f2e-7211-4230-bdf0-1be8460bc396"
                                    className="text-dark m-0"
                                >
                                    139 Supporters
                                </span>
                                <span
                                    id="8362267b-177c-4b47-a55e-1e48a7ed579f"
                                    className="text-dark m-0"
                                >
                                    $2,371.00
                                </span>
                            </div>
                        </div>
                        <div
                            id="626c1caa-ff0b-443c-922a-bb977dd76f33"
                            className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                        >
                            <div
                                id="430c9560-355b-4632-ae2f-348265b0e08d"
                                className="d-flex flex-fill justify-content-end gap-2"
                            >
                                <button
                                    id="b08c9d4f-a7b1-47ff-8511-c1e579247a29"
                                    className="btn btn-success"
                                    title="Accept"
                                >
                                    Accept
                                </button>
                                <button
                                    id="4f5ecbeb-66f1-462b-b4cb-8c3c7b108561"
                                    className="btn btn-danger"
                                    title="Reject"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        id="03dccec7-872d-42fd-954e-50c131a314e5"
                        className="rounded-bottom bg-warning-subtle mt-0 py-1"
                    >
                        <div
                            id="2a98af0a-e898-41bb-b600-89a393d43045"
                            className="d-flex justify-content-center gap-2 text-warning-emphasis"
                        >
                            ONGOING
                        </div>
                        <div
                            id="3cf1e3ed-3125-41e8-9933-247c53e6df16"
                            className="d-flex justify-content-center mt-0 form-text text-warning-emphasis"
                        >
                            <span id="8ca2b2c6-a551-4b3a-b2bc-44abc30a8215">
                                Time remaining to fulfill: 14d 10h 37m 19s
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ),
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-4.png",
        title: "welcomePage.steps.step4.title",
        text: "welcomePage.steps.step4.text",
        bgColor: "bg-white",
        code: (
            <div
                id="7d56b1e3-66a9-45dc-b88a-7304c786b8cd"
                className="border rounded w-100"
                // style={{
                //     boxShadow:
                //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                // }}
            >
                <div
                    id="e668649a-7a57-46c3-a78b-032b21aa0d96"
                    className="d-flex flex-column gap-3 p-3"
                >
                    <div
                        id="4f458939-7c73-40e1-94d8-2ba2b54c28fb"
                        className="d-flex flex-column border-bottom gap-3"
                    >
                        <h5
                            id="869e871b-f11f-4a5b-aafb-fe6d34e7bd89"
                            className="card-title"
                        >
                            Kinetic vs Potential energy
                        </h5>
                        <div id="8a197536-ddf9-40bb-99e6-1457c0a08dda">
                            <p id="9d137954-86f6-4f23-ac00-a64669c3df6b">
                                Professor Smith, I appreciate how you simplify
                                complex physics concepts.
                            </p>
                            <p id="04450aca-700e-4a20-a56b-2e89222c8d56">
                                Could you please make a video explaining the
                                difference between kinetic and potential energy
                                and how one transforms into another?
                            </p>
                            <p id="b43aeaf5-2919-4cc9-afba-a34913be530c">
                                I've watched numerous videos on this matter, but
                                I'm particularly keen to get your perspective on
                                the subject.
                            </p>
                        </div>
                    </div>
                    <div
                        id="39b1d1f2-1786-4345-9ac3-ffc07044ec53"
                        className="d-flex align-items-center justify-content-between"
                    >
                        <div
                            id="863b6b7b-dcfb-4593-8fb3-1710ac886ead"
                            className="d-flex align-items-center"
                        >
                            <img
                                id="34c57aeb-fb65-469c-930b-a5b0da8b6ff6"
                                className="me-2"
                                src={creator}
                                style={{
                                    width: "45px",
                                    height: "45px",
                                }}
                                alt=""
                            />
                            <div
                                id="619cb15a-9d90-4b7c-a18b-734c1d93507c"
                                className="d-flex flex-column"
                            >
                                <div
                                    id="862eda55-018c-43fb-9be3-7fad88e4d308"
                                    className="d-flex align-items-center gap-1"
                                >
                                    <div
                                        id="f071261e-862a-4790-88c7-e8948d16fa7f"
                                        className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                    >
                                        <span id="49102f12-8f1f-4cd4-aad3-b1214044a6cf">
                                            Prof. John Smith
                                        </span>
                                    </div>
                                    <MdOutlineVerified
                                        id="8cdb7c32-4079-4521-9910-487d3bf276a2"
                                        className="text-primary"
                                        style={{ marginTop: "0.2rem" }}
                                    />
                                </div>
                                <span
                                    id="f9b6ef0d-27c0-4165-aa62-268a6dd8f1aa"
                                    className="form-text mt-0"
                                >
                                    @johnsmith
                                </span>
                            </div>
                        </div>
                        <div
                            id="a0e105b6-cbea-4f2e-ba4e-4021aa718038"
                            className="d-flex flex-column gap-3 flex-sm-row align-items-center"
                        >
                            <span
                                id="0ce5c632-e339-4540-9d4f-3d6cdf36ce65"
                                className="text-dark m-0"
                            >
                                139 Supporters
                            </span>
                            <span
                                id="3c72924f-b74b-403d-a517-148dae278179"
                                className="text-dark m-0"
                            >
                                $2,371.00
                            </span>
                        </div>
                    </div>
                    <div
                        id="0c30ad4f-08c2-4ef2-9f74-fe4d9337e3a7"
                        className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                    >
                        <div
                            id="caac4acf-9d0e-41e4-a8b1-b6f109481005"
                            className="d-flex flex-fill justify-content-end gap-2"
                        >
                            <button
                                id="e2c50669-5a60-4b20-8623-92aab52226c1"
                                className="btn btn-success"
                                title="Accept"
                            >
                                Mark as fulfilled
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    id="67518444-ad0b-444b-a34b-140ba1988842"
                    className="rounded-bottom bg-info-subtle mt-0 py-1"
                >
                    <div
                        id="82b30e04-70e6-4821-902a-08a223f3eb25"
                        className="d-flex justify-content-center gap-2 text-info-emphasis"
                    >
                        ACCEPTED
                    </div>
                    <div
                        id="2bf2f7dd-8977-4028-ab1e-903182c225dc"
                        className="d-flex justify-content-center mt-0 form-text text-info-emphasis"
                    >
                        <span id="d763a0e6-6ebf-4375-8b39-dcaaaba91702">
                            Time remaining to fulfill: 7d 23h 41m 57s
                        </span>
                    </div>
                </div>
            </div>
        ),
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-5.png",
        title: "welcomePage.steps.step5.title",
        text: "welcomePage.steps.step5.text",
        bgColor: "bg-white",
        code: (
            <div
                id="678233cd-dac7-4233-80b0-b9567507fa81"
                className="d-flex flex-column gap-3"
            >
                <div
                    id="8f44c021-fe67-495a-ae57-39776fac91a0"
                    className="row justify-content-end px-3"
                >
                    <div
                        id="0e0bc93c-7d34-4526-84a7-cb178a506f1a"
                        className="col col-sm-9 col-md-12 col-lg-9 col-xl-7 border rounded p-3 d-flex flex-column"
                        // style={{
                        //     boxShadow:
                        //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        // }}
                    >
                        <img
                            id="e03423d4-3ef3-4c69-adeb-e8f8515a3f1c"
                            className=""
                            src={thumbnail}
                            alt=""
                        />
                        <h6
                            id="b7d51a38-4b4f-4933-8201-f62d3d840ccc"
                            style={{ marginTop: "-15px", zIndex: "1" }}
                        >
                            Kinetic vs Potential energy
                        </h6>
                        <span id="a684a125-e33b-4752-9a68-75266a6047e0">
                            Prof. John Smith
                        </span>
                    </div>
                </div>
                <div
                    id="1c875396-da63-477a-8333-fba2ed0ced82"
                    className="border rounded w-100"
                    // style={{
                    //     boxShadow:
                    //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    // }}
                >
                    <div
                        id="553a7bdf-bb3b-492c-a006-f50746991741"
                        className="d-flex flex-column gap-3 p-3"
                    >
                        <div
                            id="5d15400c-669f-427c-8f69-ceefde9c2b44"
                            className="d-flex flex-column border-bottom gap-3"
                        >
                            <h5
                                id="4b638c3f-c2b3-4f84-9c60-eff38c1b3e8c"
                                className="card-title"
                            >
                                Kinetic vs Potential energy
                            </h5>
                            <div id="12c559ae-f95f-4fbf-b2ba-d69dcf25bf0a">
                                <p id="6ee00e7d-9c32-46f2-a49a-9970f291757f">
                                    Professor Smith, I appreciate how you
                                    simplify complex physics concepts.
                                </p>
                                <p id="c1a6e66b-2579-43fd-b02e-24af676ba470">
                                    Could you please make a video explaining the
                                    difference between kinetic and potential
                                    energy and how one transforms into another?
                                </p>
                                <p id="b735daa6-19af-452d-8540-bd91ded3d5a4">
                                    I've watched numerous videos on this matter,
                                    but I'm particularly keen to get your
                                    perspective on the subject.
                                </p>
                            </div>
                        </div>
                        <div
                            id="6b582d75-4da8-4475-95f2-3cade4629c34"
                            className="d-flex align-items-center justify-content-between"
                        >
                            <div
                                id="355c012e-fd7b-434f-8c6f-580ac424bd06"
                                className="d-flex align-items-center"
                            >
                                <img
                                    id="183c0dda-7b11-47fb-adb9-cb1c030ca4e9"
                                    className="me-2"
                                    src={creator}
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                    }}
                                    alt=""
                                />
                                <div
                                    id="68e36cc4-ed12-4d50-b8bf-041e7b5cabbb"
                                    className="d-flex flex-column"
                                >
                                    <div
                                        id="73df826f-9999-4a5b-b362-330f7a04d03f"
                                        className="d-flex align-items-center gap-1"
                                    >
                                        <div
                                            id="0e1820ce-3b95-4a00-8035-5b4eae7c3819"
                                            className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                        >
                                            <span id="95b39f00-02c7-444e-bab5-7d2b5421df66">
                                                Prof. John Smith
                                            </span>
                                        </div>
                                        <MdOutlineVerified
                                            id="afa1b34b-81eb-4bbd-bdb3-c0c6f3b718c9"
                                            className="text-primary"
                                            style={{ marginTop: "0.2rem" }}
                                        />
                                    </div>
                                    <span
                                        id="8bfa2e0c-94fb-4eaf-9329-20491d4d8d4a"
                                        className="form-text mt-0"
                                    >
                                        @johnsmith
                                    </span>
                                </div>
                            </div>
                            <div
                                id="898fa062-e624-41fc-bbe9-7be17da58b44"
                                className="d-flex flex-column gap-3 flex-sm-row align-items-center"
                            >
                                <div
                                    id="a321c05f-f72d-492f-aa4a-219f2310793a"
                                    className="d-flex gap-1"
                                >
                                    <img
                                        id="cea288f9-7650-400f-99f5-8dfc2cffa461"
                                        src={star}
                                        style={{ width: "1rem" }}
                                        alt=""
                                    />
                                    <span
                                        id="2e9c3fbc-6139-4763-9625-8e2070ec666f"
                                        className="text-dark m-0"
                                    >
                                        4.8
                                    </span>
                                </div>
                                <span
                                    id="90a0943f-973a-4cbc-9db8-1825b8223642"
                                    className="text-dark m-0"
                                >
                                    139 Supporters
                                </span>
                                <span
                                    id="c1f20140-b1a3-4e58-a2c0-bc42c6c63575"
                                    className="text-dark m-0"
                                >
                                    $2,371.00
                                </span>
                            </div>
                        </div>
                        <div
                            id="435bc344-7591-46de-97f2-515296eb6a84"
                            className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                        >
                            <div
                                id="005e9077-cc85-4720-9cd6-cd37632a8507"
                                className="d-flex flex-fill justify-content-end gap-2"
                            >
                                <button
                                    id="a23f9cb5-1c13-440a-ae6d-0ae71f61e604"
                                    className="btn btn-success"
                                >
                                    View the content
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        id="472898b0-85c4-4056-8e33-a7f9b0da84de"
                        className="rounded-bottom bg-success-subtle mt-0 py-1"
                    >
                        <div
                            id="d8ab3931-4741-4227-b6bc-47bbed229757"
                            className="d-flex justify-content-center gap-2 text-success-emphasis"
                        >
                            FULFILLED
                        </div>
                    </div>
                </div>
                <div
                    id="9cb323a9-a604-4edf-a4bd-957fc9271b24"
                    className="row justify-content-end px-3"
                >
                    <div
                        id="30132b46-b17f-4399-b2ed-0b75a17e579f"
                        className="col col-sm-9 col-md-12 col-lg-9 border rounded p-3"
                        // style={{
                        //     boxShadow:
                        //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        // }}
                    >
                        <h6 id="80da10cd-b26e-41af-9cb5-8337d395513a">
                            Pay out earnings
                        </h6>
                        <hr id="455dd037-b6d2-45bb-9af6-6e8b6bd67397" />
                        <div
                            id="78e4238b-e243-4401-8f3b-4d0f501fdb41"
                            className="d-flex justify-content-between gap-3"
                        >
                            <div
                                id="eea32b3f-c413-4fd9-ba07-16308896110c"
                                className="d-flex gap-2 align-items-center"
                            >
                                <label
                                    id="b68778bc-4257-4527-9951-6795b912d308"
                                    htmlFor="pledgeAmountInput"
                                    className="col-form-label"
                                >
                                    Earnings:
                                </label>
                                <span
                                    id="7a9c6bcf-6c32-4b38-95e9-fb00280f809a"
                                    className="text-primary fw-bold"
                                >
                                    $2,371.00
                                </span>
                            </div>
                            <button
                                id="438187ea-cf08-459a-909c-8bdb9eab453b"
                                className="btn btn-dark"
                            >
                                Pay out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ),
    },
    {
        image: "https://storage.googleapis.com/ablebees-public/frontend/welcome/step-6.png",
        title: "welcomePage.steps.step6.title",
        text: "welcomePage.steps.step6.text",
        bgColor: "bg-white",
        code: (
            <div
                id="c727ece3-b8db-4a9a-b038-26e3badccb17"
                className="d-flex flex-column gap-3"
            >
                <div
                    id="2cf71b81-30aa-401f-ad7c-26e649008ae8"
                    className="row justify-content-end px-3"
                >
                    <div
                        id="a392d371-a308-435d-9690-5abfa15a54b5"
                        className="col col-sm-9 col-md-12 col-lg-9 border rounded p-3"
                        // style={{
                        //     boxShadow:
                        //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        // }}
                    >
                        <h6 id="c09710bc-479f-4cdf-81ff-be3beaafb4e1">
                            Rate the content
                        </h6>
                        <hr id="c56a4e7d-5a56-4bc0-99b0-eb9bbed1597e" />
                        <div
                            id="8a07fa98-d8db-4801-ae02-74211f03715b"
                            className="d-flex justify-content-between"
                        >
                            <div
                                id="230d8edc-174a-46e0-9cdc-4c5bcd653a41"
                                className="d-flex gap-2"
                            >
                                <img
                                    id="2de1011f-8261-4129-b67c-18078ca2ae05"
                                    src={star}
                                    style={{ width: "2rem" }}
                                    alt=""
                                />
                                <img
                                    id="7289d385-a635-4330-8ad0-99fe8ac749f2"
                                    src={star}
                                    style={{ width: "2rem" }}
                                    alt=""
                                />
                                <img
                                    id="a5d6fd39-ec4c-454a-927d-40469fe7ff30"
                                    src={star}
                                    style={{ width: "2rem" }}
                                    alt=""
                                />
                                <img
                                    id="a0d16f13-fb1a-4bd4-bb2c-7664152ffe14"
                                    src={star}
                                    style={{ width: "2rem" }}
                                    alt=""
                                />
                                <img
                                    id="ef421fce-8939-4c99-8da5-be3e4ad96c25"
                                    src={starEmpty}
                                    style={{ width: "2rem" }}
                                    alt=""
                                />
                            </div>
                            <button
                                id="0ac31415-c77f-4d0e-b944-99cd02591c25"
                                className="btn btn-dark"
                            >
                                Rate
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    id="ea316851-f3a8-471f-aec6-54623feef7f7"
                    className="border rounded w-100"
                    // style={{
                    //     boxShadow:
                    //         "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    // }}
                >
                    <div
                        id="0fbc973b-4e0b-4d3b-95be-24b962737d13"
                        className="d-flex flex-column gap-3 p-3"
                    >
                        <div
                            id="c34c94c8-eced-43ff-9772-330ff581f5c4"
                            className="d-flex flex-column border-bottom gap-3"
                        >
                            <h5
                                id="a993fedc-5c8d-41fa-8847-85df9acdf586"
                                className="card-title"
                            >
                                Kinetic vs Potential energy
                            </h5>
                            <div id="3be06329-3270-4e91-8e03-5861a7fcb9ab">
                                <p id="55c4a6a2-e185-4124-a234-fd902bcd5343">
                                    Professor Smith, I appreciate how you
                                    simplify complex physics concepts.
                                </p>
                                <p id="57acf7ac-4a4f-40b1-aeb9-9e9cd8ce7af9">
                                    Could you please make a video explaining the
                                    difference between kinetic and potential
                                    energy and how one transforms into another?
                                </p>
                                <p id="8117e35e-68fc-45ca-a6bf-880cd57ddde8">
                                    I've watched numerous videos on this matter,
                                    but I'm particularly keen to get your
                                    perspective on the subject.
                                </p>
                            </div>
                        </div>
                        <div
                            id="8c5df4d6-2de5-496c-8925-594e9de81567"
                            className="d-flex align-items-center justify-content-between"
                        >
                            <div
                                id="6a1fe38b-4922-418f-bf93-d8d8ccd90e9c"
                                className="d-flex align-items-center"
                            >
                                <img
                                    id="f6ce3bed-3345-4f05-ba1b-66bb04c25c1f"
                                    className="me-2"
                                    src={creator}
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                    }}
                                    alt=""
                                />
                                <div
                                    id="cc936a73-8a6b-4f15-a009-bf566a8675a4"
                                    className="d-flex flex-column"
                                >
                                    <div
                                        id="c0d18c67-3210-4efd-bde4-ad44dd93d830"
                                        className="d-flex align-items-center gap-1"
                                    >
                                        <div
                                            id="f9cbd2a5-6c0a-4d5b-99cf-a59a03a43bde"
                                            className="d-flex flex-wrap gap-1 form-text text-black fw-bolder"
                                        >
                                            <span id="1cf58d28-0703-4ee8-9e2e-b643b61e7d4f">
                                                Prof. John Smith
                                            </span>
                                        </div>
                                        <MdOutlineVerified
                                            id="9bf1dcba-3718-4615-b142-06976cb07be7"
                                            className="text-primary"
                                            style={{ marginTop: "0.2rem" }}
                                        />
                                    </div>
                                    <span
                                        id="a41a0dfa-0099-4cc8-bfa0-2bd6d14fbfa1"
                                        className="form-text mt-0"
                                    >
                                        @johnsmith
                                    </span>
                                </div>
                            </div>
                            <div
                                id="cb38e804-ad49-4228-b33a-ae207acf714a"
                                className="d-flex flex-column gap-3 flex-sm-row align-items-center"
                            >
                                <div
                                    id="e5ae357a-6a40-4642-8b0f-8bd74ef6aa0b"
                                    className="d-flex gap-1"
                                >
                                    <img
                                        id="9722ec40-62f1-49bc-8eb3-1e4eb3734271"
                                        src={star}
                                        style={{ width: "1rem" }}
                                        alt=""
                                    />
                                    <span
                                        id="8a2714b2-3a8c-4cc6-a19e-f5808191b6fc"
                                        className="text-dark m-0"
                                    >
                                        4.8
                                    </span>
                                </div>
                                <span
                                    id="40242dc6-8b08-457b-b0a2-f1da84c4a246"
                                    className="text-dark m-0"
                                >
                                    139 Supporters
                                </span>
                                <span
                                    id="5309d6ca-8c6c-461c-bd76-1ae1bb3fea8e"
                                    className="text-dark m-0"
                                >
                                    $2,371.00
                                </span>
                            </div>
                        </div>
                        <div
                            id="423c6b06-dd3e-4cad-838a-63fa1adca0d6"
                            className="d-flex flex-wrap align-items-center justify-content-between border-top pt-3"
                        >
                            <div
                                id="04c2445b-ec6b-4e24-921d-8100a83f572d"
                                className="d-flex flex-fill justify-content-end gap-2"
                            >
                                <button
                                    id="538cea61-86e4-42bc-8b2c-ff0c0bfb351e"
                                    className="btn btn-warning"
                                >
                                    Rate the content
                                </button>
                                <button
                                    id="aeb7ac83-dc7f-4081-b0ad-a7a54c95ebc5"
                                    className="btn btn-success"
                                >
                                    View the content
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        id="e734a8d5-c621-4bb1-b4ea-e546e170ec1e"
                        className="rounded-bottom bg-success-subtle mt-0 py-1"
                    >
                        <div
                            id="ad89c971-07e7-4fb7-95de-b23b5a59b794"
                            className="d-flex justify-content-center gap-2 text-success-emphasis"
                        >
                            FULFILLED
                        </div>
                    </div>
                </div>
            </div>
        ),
    },
];

export const renderUsageStepCard = (
    t: TFunction,
    item: UsageStepCard,
    index: number,
    isLast: boolean = false
) => {
    return (
        <div
            id="519a8b64-2482-4fcb-9986-1653c4fb9511"
            key={index}
            className={`${classes["creator-type-card"]} row ${
                index === 0 ? "pt-5" : "pt-4"
            } pb-4 mx-0 ${item.bgColor}`}
        >
            <div
                id="64808b86-8ef7-4906-b0ad-998db61fa72e"
                className="col d-flex justify-content-center px-0"
            >
                <div
                    id="0118fd2c-ec48-4bd5-a981-4403e83d8987"
                    className="row container px-0 py-3"
                >
                    <div
                        id="833525d8-7948-4479-b64b-d3f5321eb2a8"
                        className={`col-md-6 d-flex flex-column justify-content-center ${
                            index % 2 === 1 && "order-md-2"
                        }`}
                    >
                        <h2
                            id="3f2adae2-97c5-4893-bd54-90f958ff68e5"
                            className="fw-normal lh-1 mb-3"
                        >
                            {t(item.title)}
                        </h2>
                        <p
                            id="4ed4fada-063c-4a92-941d-736f966904d0"
                            className="lead"
                        >
                            {t(item.text)}
                        </p>
                    </div>
                    <div
                        id="e3613b71-cd41-4bab-a710-5fcfdf495196"
                        className={`col-md-6 ${
                            index % 2 === 1 && "order-md-1"
                        } d-flex justify-content-center`}
                    >
                        {item.code}
                    </div>
                </div>
            </div>
        </div>
    );
};
