import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import classes from "./FaqPage.module.scss";
import Faqs from "./Faqs/Faqs";
import { useTranslation } from "react-i18next";

const FaqPage = () => {
    const { t } = useTranslation();
    const [viewAsContentCreator, setViewAsContentCreator] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div
                id="07b9c316-21b0-4544-a0ec-9279dafc8b72"
                className={`p-3 form-text text-dark container ${classes.content}`}
            >
                <h2 id="659f7e31-ad06-4ed1-ae89-b44853eed70e" className="mb-3">
                    {t("faqPage.frequentlyAskedQuestions")}
                </h2>
                <Faqs
                    id="9dd3005d-03c4-4b31-8069-1ba2bdbe71ab"
                    viewAsContentCreator={viewAsContentCreator}
                    setViewAsContentCreator={setViewAsContentCreator}
                />
            </div>
            <Footer id="a4c664e8-e21e-4997-be1c-ceb8a01688a7" />
        </>
    );
};

export default FaqPage;
