import React, { useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import classes from "./WelcomePage.module.scss";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Faqs from "../FaqPage/Faqs/Faqs";
import { renderUsageStepCard, UsageStepCard, UsageSteps } from "./UsageSteps";

const WelcomePage = () => {
    const { t } = useTranslation();
    const [viewAsContentCreator, setViewAsContentCreator] = useState(false);
    const renderedUsageSteps = UsageSteps.map(
        (item: UsageStepCard, index: number) =>
            renderUsageStepCard(t, item, index, index === UsageSteps.length - 1)
    );

    // const renderedAlreadyOnAblebeesCreators = AlreadyOnAblebeesCreators.map(
    //     (cards: AlreadyOnAblebeesCreatorCard[]) => {
    //         return (
    //             <div
    //                 id="ad731503-b12b-4756-8035-39defbaa67aa"
    //                 className="d-flex flex-column justify-content-center flex-md-row gap-3"
    //             >
    //                 {cards.map((card: AlreadyOnAblebeesCreatorCard) =>
    //                     renderAlreadyOnAblebeesCreatorCard(card)
    //                 )}
    //             </div>
    //         );
    //     }
    // );

    const el = useRef<null | HTMLDivElement>(null);

    const handleClick = () => {
        el.current!.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <main id="b13aaee6-2da5-4958-b81a-febf7a0059a2">
                <div
                    id="48faa1e7-009b-455c-9ef3-942ffed7bd96"
                    className="row mx-0"
                >
                    <div
                        id="938aa61e-ee5a-4796-89c2-136ed6d53986"
                        className="col px-0"
                    >
                        <div
                            id="2ea4d717-d0fa-4c26-ae57-a226560c47e1"
                            className={classes.banner}
                        >
                            <div
                                id="a28ba3d4-b8bd-40a6-8f92-f92c5a33c9eb"
                                className={classes["video-container"]}
                            >
                                <div
                                    id="dd57f3fc-d64d-43e1-bd4c-3ad75c52107c"
                                    className={classes["color-overlay"]}
                                />
                                <video
                                    id="d87129b2-8808-4e9f-8eb2-6a0ee3eedf6e"
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                    className={classes.video}
                                    playsInline={true}
                                >
                                    <source
                                        id="b37ed6d8-f24e-431a-9309-a66c8f21be30"
                                        src="https://storage.googleapis.com/ablebees-public/frontend/welcome/banner-bg-video.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                            <div
                                id="6e6ef5fa-fdd2-444c-8d8f-84679b050d9f"
                                className={`${classes.inner} h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-3 p-0`}
                            >
                                <p
                                    id="f1e1aba4-60ba-4687-9e41-3125fa79f3cb"
                                    className="text-center"
                                >
                                    <Trans
                                        id="08083d71-f468-4543-a52e-c493179d2059"
                                        i18nKey="welcomePage.slogan"
                                        components={[
                                            <br id="39b0905e-a1e4-4d29-8d13-ebf160519ee6" />,
                                        ]}
                                    />
                                </p>
                                <span id="a3ac4402-3125-4ea2-a188-8dfd7a3a08b1">
                                    <button
                                        id="04e71a16-13ee-44b8-9a14-660f95751368"
                                        className="btn btn-danger"
                                        onClick={handleClick}
                                    >
                                        {t("welcomePage.wantToKnowHow")}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="07f16c67-3065-450b-982f-2cea540c8d1c"
                    className="row mx-0 pt-3"
                    ref={el}
                >
                    <div
                        id="0232315b-e774-47eb-bc8e-24f4bf0e7a57"
                        className="col px-0 d-flex flex-column align-items-center gap-3"
                    >
                        <div
                            id="92daab31-a7ae-4f43-8087-45b577f8bab0"
                            className="row mx-0 w-100"
                        >
                            <div
                                id="a3d34c78-31cc-48ff-a389-4afe090e545a"
                                className="col px-0"
                            >
                                {renderedUsageSteps}
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div*/}
                {/*    id="d52be579-5dd2-41aa-b152-cff09cb9ee7f"*/}
                {/*    className="container py-5"*/}
                {/*>*/}
                {/*    <div*/}
                {/*        id="e90bc473-db26-4fe0-bfea-52f3f54373fa"*/}
                {/*        className="row justify-content-center"*/}
                {/*    >*/}
                {/*        <div*/}
                {/*            id="7b70f04c-c20b-44df-9fb7-11ab40a07068"*/}
                {/*            className="row mb-4"*/}
                {/*        >*/}
                {/*            <div*/}
                {/*                id="cfc7ccc6-e023-4474-b68a-508678678853"*/}
                {/*                className="col text-center"*/}
                {/*            >*/}
                {/*                <h2 id="026b248c-7d85-4268-9966-1ab92713a405">*/}
                {/*                    {t("welcomePage.alreadyOnAblebees")}*/}
                {/*                </h2>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            id="7ab26f94-af13-469e-b089-0d54f34d31f0"*/}
                {/*            className="d-flex flex-column gap-3"*/}
                {/*        >*/}
                {/*            {renderedAlreadyOnAblebeesCreators}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*FAQ*/}
                <div
                    id="49bb25e5-4408-4571-940e-270dcac76eae"
                    className="row mx-0 py-5"
                >
                    <div
                        id="05b27ba1-7267-4616-81fd-38383b700a78"
                        className="col d-flex flex-column gap-2 align-items-center px-0"
                    >
                        <div
                            id="72fbe264-7124-4952-b14a-6fae4ce7df48"
                            className="row mx-0"
                        >
                            <div
                                id="5cdab372-e4b1-40ec-af3c-f148a0169147"
                                className="col text-center px-0"
                            >
                                <h2 id="1e9568fa-2c21-45a0-8d30-3453ffa48ff1">
                                    {t("faqPage.frequentlyAskedQuestions")}
                                </h2>
                            </div>
                        </div>
                        <div
                            id="57b610b2-d612-4530-a697-ed9466eb881c"
                            className="row container"
                        >
                            <div
                                id="23294e6d-8341-4c7f-b5a7-ade9887974ab"
                                className="col"
                            >
                                <Faqs
                                    id="02582c9b-d40c-4a76-8a1a-d073edb465f8"
                                    centerTabs={true}
                                    viewAsContentCreator={viewAsContentCreator}
                                    setViewAsContentCreator={
                                        setViewAsContentCreator
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*Ready?*/}
                <div
                    id="17308a87-89eb-4fe6-b8e8-43a7d0f07d61"
                    className="row mx-0 mt-5 py-5 bg-light"
                >
                    <div
                        id="3edda6de-d4fe-43d4-8f78-d8839bfdaa8c"
                        className="col d-flex flex-column gap-2 align-items-center px-0"
                    >
                        <h2
                            id="02649b8b-f6f1-4de5-b19d-3fc61b780d1a"
                            className="text-center"
                        >
                            {t("welcomePage.readyToStartGetPetitions.part1")}{" "}
                            {viewAsContentCreator
                                ? t(
                                      "welcomePage.readyToStartGetPetitions.part3"
                                  )
                                : t(
                                      "welcomePage.readyToStartGetPetitions.part2"
                                  )}
                            ?
                        </h2>
                        <Link
                            // id="welcome-page-to-signup"
                            id="ce19aedf-3de6-43fd-99dd-7e60c03dfc09"
                            to={"/signup"}
                            className="btn btn-danger mt-2"
                        >
                            {t("welcomePage.joinAblebees")}
                        </Link>
                    </div>
                </div>
            </main>
            <Footer
                id="085fb372-517c-4e6b-8186-97dbddf57e8d"
                showBorderTop={false}
            />
        </>
    );
};
export default WelcomePage;
